export function smoothScrollToBottom(elementId, delay = 300) {
  setTimeout(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scroll({
        top: element.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, delay);
}

export function jumpToBottom(elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    element.scroll({
      top: element.scrollHeight,
    });
  }
}
